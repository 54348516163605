import {
    Abstract
} from './Abstract';

export class AccountingPolicyType extends Abstract {
    constructor(session) {
        super('accounting/AccountingPolicyType', session);
        this.name = null;
        this.key_name = null;
    }
}