export const actions = {
    "new": { name: 'Nuevo', value: 'new', emit: "new", class: "p-button-new mr-2", icon: "pi pi-plus" },
    "save": { name: 'Guardar', value: 'save', emit: "save", class: "p-button-primary mr-2", icon: "pi pi-save" },
    "add": { name: 'Agregar', value: 'add', emit: "add", class: "p-button-add mr-2", icon: "pi pi-database" },
    "search": { name: 'Buscar', value: 'search', emit: "search", class: "p-button-search mr-2", icon: "pi pi-search" },
    "refresh": { name: 'Recargar', value: 'refresh', emit: "refresh", class: "p-button-info mr-2", icon: "pi pi-refresh" },
    "cancel": { name: 'Cancelar', value: 'cancel', emit: "cancel", class: "p-button-danger mr-2", icon: "pi pi-times" },
    "delete": { name: 'Eliminar', value: 'delete', emit: "delete", class: "p-button-danger mr-2", icon: "pi pi-trash" },
    "send": { name: 'Enviar', value: 'send', emit: "send", class: "p-button-warning mr-2", icon: "pi pi-envelope" },
    "list": { name: 'Registros', value: 'list', emit: "list", class: "p-button-info mr-2", icon: "pi pi-table" },
    "cargar": { name: 'Cargar', value: 'cargar', emit: "cargar", class: "p-button-info mr-2", icon: "pi pi-globe" },
    "aplicar": { name: 'Aplicar', value: 'aplicar', emit: "aplicar", class: "p-button-success mr-2", icon: "pi pi-eject" },
    "extraer": { name: 'Extraer', value: 'extraer', emit: "extraer", class: "p-button-add mr-2", icon: "pi pi-download" },
    "agregar": { name: 'Agregar', value: 'agregar', emit: "agregar", class: "p-button-add mr-2", icon: "pi pi-database" },
}